

























import { Component, Vue, Prop } from "vue-property-decorator";
import { MealsModule } from "@/store/modules/meals";
import {Meal} from '@/models/meal'

@Component({
  name: "MealCard",
  components: {},
})
export default class MealCard extends Vue {
  @Prop(Number) readonly mealIndex!: number;
  @Prop(String) readonly day!: string;

  private toggleLock() {
    MealsModule.toggleLockMeal(this.mealIndex);
  }

  get meal() {
    return MealsModule.meals[this.mealIndex]?.meal || new Meal();
  }

  get isLocked() {
    return MealsModule.meals[this.mealIndex]?.locked || false;
  }
}
