












import { Component, Vue } from "vue-property-decorator";
import { MealsModule } from "@/store/modules/meals";

@Component({
  name: "BottomAppBar",
  components: {},
})
export default class BottomAppBar extends Vue {
  private refreshMeals() {
    MealsModule.refresh();
  }
}
