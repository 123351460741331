






































import { Component, Vue } from "vue-property-decorator";
import { MealsModule } from "@/store/modules/meals";
import BottomAppBar from "@/components/layout/BottomAppBar.vue";
import MealCard from "@/components/meals/MealCard.vue"

@Component({
  components: {
    BottomAppBar,
    MealCard,
  },
})
export default class App extends Vue {
  private isLoading = false;

  private days: string[] = [
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi",
    "Dimanche",
  ];

  mounted() {
    MealsModule.fetchAll();
  }
}
